<template>
  <div class="ancestor-field-set">
    <div class="label">I am curious about my...</div>
    <div class="relation-wrapper">
      <input
        :class="{'has-error': errors && errors.relation}"
        maxlength="50"
        placeholder="Type a relation"
        :value="relation"
        ref="relation"
        @input="relationInput"
      />
      <close-icon v-if="relation" class="clear-icon" @mousedown.prevent.stop="clearRelation"></close-icon>
    </div>
    <transition name="fade">
      <p class="error" v-if="errors && errors.relation">{{ errors.relation }}</p>
    </transition>

    <div class="ancestor-name">
      <input
        class="first-name"
        placeholder="First name"
        :class="{'has-error': errors && errors.firstName}"
        @input="firstNameInput"
      />
      <input
        class="last-name"
        :disabled="lastNameDisabled"
        :value="lastName"
        :class="{'has-error': errors && errors.lastName}"
        placeholder="Last name"
        @input="lastNameInput"
      />
    </div>
    <transition name="fade">
      <p class="error" v-if="errors && (errors.firstName || errors.lastName)">
        {{ errors.firstName || errors.lastName }}
      </p>
    </transition>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  props: {
    relation: String,
    lastName: String,
    errors: Object,
    lastNameDisabled: Boolean,
  },
  methods: {
    relationInput(event) {
      this.$emit('input-relation', event.target.value);
    },
    firstNameInput(event) {
      this.$emit('input-first-name', event.target.value);
    },
    lastNameInput(event) {
      this.$emit('input-last-name', event.target.value);
    },
    clearRelation() {
      this.$emit('input-relation', '');
      this.$refs.relation.focus();
    },
  },
  components: {CloseIcon},
};
</script>

<style lang="scss" scoped>
.ancestor-field-set {
  input {
    width: 100%;
  }
  .relation-wrapper {
    position: relative;
    .clear-icon {
      position: absolute;
      display: block;
      right: 5px;
      top: 9px;
      cursor: pointer;
      color: #777;
      transition: opacity 0.2s ease;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  .label {
    margin: 30px 0 10px;
  }

  .ancestor-name {
    display: flex;
    margin: 15px 0;
    .first-name {
      margin-right: 15px;
    }
  }
}
</style>
